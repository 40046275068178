import type { ClientInferRequest, ClientInferResponseBody } from '@ts-rest/core'
import type { ExchangeResultType, PairsResultType, contract } from '@forgd/contract'

import type { AccountResult, AuthResult } from '#tools/state/useExchange'

export type AMMStrategy = ClientInferResponseBody<typeof contract.amm2.getAmm2byId, 200>
type Strategies = ClientInferResponseBody<typeof contract.amm2.getAmm2, 200>
type Exchanges = ClientInferResponseBody<
  typeof contract.amm2.getAmm2Exchanges,
  200
>
type TradingPairs = ClientInferResponseBody<
  typeof contract.amm2.getAmm2Pairs,
  200
>
export type Coins = ClientInferResponseBody<typeof contract.getCoins, 200>
export type SplitEstimate = ClientInferResponseBody<
  typeof contract.amm2.postAmm2GetSplitEstimate,
  200
>
type SplitEstimateArgs = ClientInferRequest<
  typeof contract.amm2.postAmm2GetSplitEstimate
>
type SaveArgs = ClientInferRequest<typeof contract.amm2.postAmm2>
type Configuration = ClientInferResponseBody<typeof contract.amm2.postAmm2>
type AuthParams = ClientInferResponseBody<
  typeof contract.amm2.getAmm2ExchangeConfig,
  200
>

type Summary = ClientInferResponseBody<typeof contract.amm2.getAmm2Summary, 200>

interface Coin {
  symbol: string
  id: string
  name: string
  image: string
}

type DeployResult = ClientInferResponseBody<
  typeof contract.amm2.deployAmm2Strategy
>

export interface SelectedExchange {
  exchange?: ExchangeResultType | null
  pairs?: Array<PairsResultType>
  pair?: PairsResultType | null
  base?: Coin | null
  quote?: Coin | null
  account?: AccountResult | null
  auth?: AuthResult | null
}

export interface SelectedExchanges {
  primary: SelectedExchange
  secondary: SelectedExchange
}

const activeStrategyStatuses = ['active', 'in-review']
const draftStrategyStatuses = ['draft', 'deactivated']

export const useAMM = defineStore('amm', () => {
  const auth = useAuth()

  const client = useClient()

  const strategies: Ref<Strategies> = ref([])
  const activeStrategies = computed(
    () => summary.value?.filter(s => activeStrategyStatuses.includes(s.status)).length,
  )
  const draftStrategies = computed(
    () =>
      summary.value?.filter(
        s => draftStrategyStatuses.includes(s.status) && s.exchanges.length > 0,
      ).length,
  )

  const exchanges: Ref<Array<ExchangeResultType>> = ref([])

  const configuration: Ref<Configuration | null> = ref(null)
  const splitEstimate: Ref<SplitEstimate | null> = ref(null)

  const deployedStrategy: Ref<DeployResult | null> = ref(null)

  const summary: Ref<Summary | null> = ref(null)

  const showSecondaryExchange = computed(() => {
    return (
      configuration.value?.strategy === 'arbitrage'
      || configuration.value?.strategy === 'migrate'
    )
  })

  const selectedExchangePrimary: Ref<SelectedExchange> = ref({})
  const selectedExchangeSecondary: Ref<SelectedExchange> = ref({})

  const coins = ref<Coins>([])

  const step = ref(0)
  const steps = [
    'Configure',
    'Connect & Fund',
    'Confirm',
  ]

  async function getStrategy(id: string) {
    const res = await client.amm2.getAmm2byId({
      params: {
        id,
      },
    })

    return res.body as AMMStrategy
  }

  async function getStrategies() {
    if (!auth.project) {
      return
    }
    const res = await client.amm2.getAmm2({
      params: {
        projectId: auth.project.id,
      },
    })

    strategies.value = res.body as Strategies

    return res.body as Strategies
  }

  async function getExchanges() {
    const res = await client.amm2.getAmm2Exchanges()
    exchanges.value = (res.body as Exchanges).sort((a, b) =>
      a.name < b.name ? -1 : 1,
    )
  }

  async function getFilteredExchanges() {
    if (!auth.project?.id) {
      return
    }

    const res = await client.amm2.getExchangesFilteredByToken({
      params: {
        projectId: auth.project?.id,
      },
    })
    exchanges.value = (res.body as Exchanges).sort((a, b) =>
      a.name < b.name ? -1 : 1,
    )
  }

  async function getTradingPairs(exchange: ExchangeResultType) {
    if (!auth.project) {
      return
    }

    const res = await client.amm2.getAmm2Pairs({
      params: {
        projectId: auth.project.id,
      },
      query: {
        exchange: exchange.id,
      },
    })

    return res.body as Array<PairsResultType>
  }

  async function getCoins() {
    if (coins.value.length) {
      return coins.value
    }

    const res = await client.coinGecko.getCoins()

    coins.value = res.body as Coins
    return coins.value
  }

  // get a single coin by id (for coin images in step 2)
  async function getCoin(id: string) {
    if (!coins.value.length) {
      await getCoins()
    }

    return coins.value.find(item => item.id === id)
  }

  async function getSplitEstimate(
    depositAmount: number,
    baseSplitPercentage: number,
    quoteSplitPercentage: number,
  ) {
    if (
      !selectedExchangePrimary.value.exchange
      || !selectedExchangePrimary.value.pair
    ) {
      console.warn('Missing data')
      return
    }

    const args: SplitEstimateArgs = {
      body: {
        depositAmount,
        baseSplitPercentage: baseSplitPercentage / 100,
        quoteSplitPercentage: quoteSplitPercentage / 100,
        primary: {
          exchangeId: selectedExchangePrimary.value.exchange.id,
          base: selectedExchangePrimary.value.pair.base,
          baseId: selectedExchangePrimary.value.pair.baseId,
          quote: selectedExchangePrimary.value.pair.quote,
          quoteId: selectedExchangePrimary.value.pair.quoteId,
        },
      },
    }

    if (
      selectedExchangeSecondary.value.exchange
      && selectedExchangeSecondary.value.pair
    ) {
      args.body.secondary = {
        exchangeId: selectedExchangeSecondary.value.exchange.id,
        base: selectedExchangeSecondary.value.pair.base,
        baseId: selectedExchangeSecondary.value.pair.baseId,
        quote: selectedExchangeSecondary.value.pair.quote,
        quoteId: selectedExchangeSecondary.value.pair.quoteId,
      }
    }

    const res = await client.amm2.postAmm2GetSplitEstimate(args)

    splitEstimate.value = res.body as SplitEstimate

    return res.body as SplitEstimate
  }

  async function saveStrategy(
    strategy: StrategySlug,
    tradingStyle: TradingStyleSlug,
    depositAmount: number,
    baseSplitPercentage: number,
    quoteSplitPercentage: number,
  ) {
    if (!auth.project) {
      return
    }

    const args: SaveArgs = {
      params: {
        projectId: auth.project.id,
      },
      body: {
        strategy,
        tradingStyle,
        depositAmount,
        baseSplitPercentage: baseSplitPercentage / 100,
        quoteSplitPercentage: quoteSplitPercentage / 100,
      },
    }

    const res = await client.amm2.postAmm2(args)
    if (res) {
      configuration.value = res.body as Configuration
      step.value = 1
    }
  }

  async function deployStrategy(when: Date, mock?: 'error' | 'success') {
    if (!configuration.value?.id) {
      return
    }

    if (mock === 'error') {
      return {
        status: 500,
        message: 'A goblin stole your strategy. Please try again.',
      }
    }

    const res = await client.amm2.deployAmm2Strategy({
      body: {
        deployDate: when.toISOString(),
        amm2Id: configuration.value.id,
      },
    })

    if (res.status === 201) {
      deployedStrategy.value = res.body as DeployResult
    }

    return res
  }

  async function getSummary() {
    if (!auth.project?.id) {
      return
    }
    const res = await client.amm2.getAmm2Summary({
      params: {
        projectId: auth.project.id,
      },
    })
    summary.value = res.body as Summary
    return res.body as Summary
  }

  function $reset() {
    selectedExchangePrimary.value = {}
    selectedExchangeSecondary.value = {}
  }

  return {
    exchanges,
    getExchanges,
    getFilteredExchanges,
    steps,
    step,
    getTradingPairs,
    getSplitEstimate,
    showSecondaryExchange,
    saveStrategy,
    configuration,
    getCoins,
    getCoin,
    selectedExchangePrimary,
    selectedExchangeSecondary,
    deployStrategy,
    deployedStrategy,
    strategies,
    activeStrategies,
    // scheduledStrategies,
    draftStrategies,
    getStrategies,
    getStrategy,
    splitEstimate,
    getSummary,
    summary,
    $reset,
  }
})
